<template>
  <Loading v-if="isLoading" />
  <div v-else class="coupons-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("coupons.coupons") }}</h1>
        <div
          class="d-flex align-items-center"
          v-if="globalPermission.coupons && globalPermission.coupons.store"
        >
          <b-button variant="primary" class="btn-add" v-b-modal.add-coupon>
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("coupons.add_coupon") }}
          </b-button>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("coupons.name") }}
          </span>
          <span v-else-if="props.column.label === 'code'" class="text-nowrap">
            {{ $t("coupons.code") }}
          </span>
          <span
            v-else-if="props.column.label === 'discount'"
            class="text-nowrap"
          >
            {{ $t("coupons.discount") }}
          </span>
          <span
            v-else-if="props.column.label === 'start_date'"
            class="text-nowrap"
          >
            {{ $t("coupons.start_date") }}
          </span>
          <span
            v-else-if="props.column.label === 'expiration_date'"
            class="text-nowrap"
          >
            {{ $t("coupons.expiration_date") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("coupons.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span class="text-nowrap" v-if="props.column.field === 'start_date'">
            {{ props.row.start_date }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            class="text-nowrap"
            v-else-if="props.column.field === 'expiry_date'"
          >
            {{ props.row.end_date }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === 'is_active'"
          >
            <b-form-checkbox
              switch
              :disabled="
                globalPermission.coupons && !globalPermission.coupons.update
              "
              v-model="props.row.is_active"
              @change="(e) => handleCouponVisibility(e, props.row.id)"
              :value="1"
              :unchecked-value="0"
            />
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' &&
              globalPermission.coupons &&
              (globalPermission.coupons.update ||
                globalPermission.coupons.destroy)
            "
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="
                    globalPermission.coupons && globalPermission.coupons.update
                  "
                  :to="{ name: 'edit-coupon', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.coupons && globalPermission.coupons.destroy
                  "
                  @click="deleteCoupon(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <add-coupon-model @update-table="update" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import AddBrandComponent from "@/components/Brands/AddBrandComponent";
import EditBrandComponent from "@/components/Brands/EditBrandComponent";
import AddCouponModel from "@/components/coupons/AddCouponModel/AddCouponModel";

export default {
  name: "CouponsList",
  components: {
    AddBrandComponent,
    EditBrandComponent,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    OverlayComponent,
    AddCouponModel,
  },
  data() {
    return {
      page_data: null,
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      brandSlug: null,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "name",
          field: "name",
          sortable: false,
        },
        {
          label: "code",
          field: "code",
        },
        {
          label: "discount",
          field: "value",
          sortable: false,
        },
        {
          label: "start_date",
          field: "start_date",
          sortable: false,
        },
        {
          label: "expiration_date",
          field: "expiry_date",
          sortable: false,
        },
        {
          label: "status",
          field: "is_active",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getCouponList();
  },
  created() {
    if (
      this.globalPermission.coupons &&
      !(
        this.globalPermission.coupons.update ||
        this.globalPermission.coupons.destroy
      )
    ) {
      this.tableHeader = this.tableHeader.filter((e) => e.label !== "Actions");
    }
  },
  methods: {
    async getCouponList() {
      try {
        const response = (await this.$http.get("admin/coupons")).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          (el.index = index + 1), (el.is_active = +el.is_active);
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCoupon(couponId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handledeleteCoupon(couponId),
          "the_coupon_has_been_successfully_deleted"
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handledeleteCoupon(couponId) {
      this.$http
        .delete(`admin/coupons/${couponId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getCouponList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    async handleCouponVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_active: checked,
        };
        const res = await this.$http.post(
          `admin/coupons/${id}?_method=put`,
          data
        );
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    update() {
      this.getCouponList();
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
