<template>
  <div class="add-coupon-modal">
    <b-modal
      modal-class="add-coupon-modal"
      id="add-coupon"
      :title="$t('coupons.add_coupon')"
      centered
      hide-footer
    >
      <validation-observer ref="addCouponValidation">
        <b-form @submit.prevent>
          <b-form-group :label="$t('coupons.name')" label-for="Name">
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                type="text"
                v-model="form_data.name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('coupons.name')"
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('coupons.code')" label-for="Code">
            <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required"
            >
              <b-form-input
                type="text"
                v-model="form_data.code"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('coupons.code')"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('coupons.discount_type')">
            <validation-provider
              #default="{ errors }"
              name="Discount type"
              rules="required"
            >
              <b-form-select
                v-model="form_data.type"
                :options="discount_type"
                :state="errors.length > 0 ? false : null"
              >
              </b-form-select>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Value"
            rules="required"
            v-if="form_data.type === 'fixed'"
          >
            <b-form-group :label="$t('coupons.value')" label-for="value">
              <b-input-group append="SAR">
                <b-form-input
                  type="number"
                  v-model="form_data.value"
                  placeholder=""
                />
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Value"
            rules="required"
            v-else
          >
            <b-form-group :label="$t('coupons.value')" label-for="value">
              <b-input-group append="%">
                <b-form-input
                  type="number"
                  v-model="form_data.value"
                  placeholder=""
                />
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>

          <b-form-group
            :label="$t('coupons.usage_limit')"
            label-for="usage limit"
          >
            <validation-provider
              #default="{ errors }"
              name="usage limit"
              rules="required"
            >
              <b-form-input
                type="number"
                v-model="form_data.usage_limit"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>

          <!-- <b-form-group :label="$t('coupons.usage_on')">
            <validation-provider
              #default="{ errors }"
              name="usage on"
              rules="required"
            >
              <b-form-select
                v-model="form_data.usage_on"
                :options="discount_type"
                :state="errors.length > 0 ? false : null"
              >
              </b-form-select>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('coupons.categories')">
            <v-select
              v-model="form_data.categories"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="title"
              :options="discount_type"
              :reduce="(option) => option.value"
            />
          </b-form-group>

          <b-form-group :label="$t('coupons.catalogs')">
            <v-select
              v-model="form_data"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="title"
              :options="catalogs"
              :reduce="(option) => option.value"
            />
          </b-form-group> -->

          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('coupons.start_date')">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.start_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group :label="$t('coupons.end_date')">
                <validation-provider
                  #default="{ errors }"
                  name="end date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.expiry_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-checkbox
            switch
            v-model="form_data.is_active"
            :value="1"
            :unchecked-value="0"
          >
            {{ $t("coupons.enable_the_coupon") }}
          </b-form-checkbox>
          <b-button
            type="submit"
            variant="primary"
            block
            @click="addCouponForm"
            class="primary-btn my-3"
            :disabled="isPending"
          >
            <span>{{ $t("coupons.save") }}</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BRow,
  BCol,
  BFormDatepicker,
  BFormCheckbox,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";
import vSelect from "vue-select";
export default {
  name: "AddCoupon",
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormDatepicker,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BInputGroup,
  },

  data() {
    return {
      isPending: false,
      form_data: {
        name: null,
        code: null,
        value: null,
        usage_limit: null,
        type: null,
        is_active: 0,
        start_date: null,
        expiry_date: null,
        // usage_on: null,
        // max_value: null,
        // categories: [],
      },
      // categories: [],
      // catalogs: [],
      discount_type: [
        {
          value: null,
          text: this.$t("coupons.please_select_the_discount_type"),
          disabled: true,
        },
        {
          value: "percentage",
          text: this.$t("coupons.percentage"),
        },
        {
          value: "fixed",
          text: this.$t("coupons.fixed"),
        },
      ],
    };
  },
  created() {
    // this.getCategories();
    // this.getCatalogsData();
  },
  methods: {
    addCouponForm() {
      this.$refs.addCouponValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          for (const key in this.form_data) {
            if (key === "is_active") {
              formData.append(key, Number(this.form_data[key]));
            } else {
              formData.append(key, this.form_data[key]);
            }
          }

          this.$http
            .post("admin/coupons", formData)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  this.$t("coupons.added_successfully"),
                  res.data.message
                );
                this.$emit("update-table", this.form_data);
                this.$bvModal.hide("add-coupon");
                this.isPending = false;
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" src="./_AddcouponModel.scss"></style>
